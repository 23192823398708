<template>
  <div class="property-valuation" v-if="!['partner', 'agent'].includes($store.state.me.role)">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item left">
        <h1>Страхование КАСКО</h1>
      </div>
      <div class="main__header__item right"></div>
    </div>
    <div class="main__content lk" style="align-items: flex-start">
      <div class="property-valuation__container lk__inputs">
        <div v-if="!company.hidden" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': company.disabled }">
          <label>{{ company.title }}</label>
          <CompanyInput
            :key="company.name"
            columnName="name"
            :placeholder="company.name"
            filterName="q"
            :currentValue="company.name"
            @change="onCompanyChange"
          />
        </div>
        <div v-if="!mark.hidden" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': mark.disabled }">
          <label>{{ mark.title }}</label>
          <SelectComponent :options="mark.options" :placeholder="mark.title" :multiple="false" @input="onMarkChange"
            :value="mark.value ? { value: mark.value } : null" />
        </div>
        <div v-if="!model.hidden" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': model.disabled }">
          <label>{{ model.title }}</label>
          <SelectComponent :options="model.options" :placeholder="model.title" :multiple="false" @input="onModelChange"
            :value="model.value ? { value: model.value } : null" />
        </div>
        <div v-if="!newTs.hidden" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': newTs.disabled }">
          <label>{{ newTs.title }}</label>
          <SelectComponent :options="newTs.options" :placeholder="newTs.title" :multiple="false" @input="onNewTsChange"
            :value="newTs.value" />
        </div>
        <div v-if="!power.hidden" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': power.disabled }">
          <label>{{ power.title }}</label>
          <input type="number" v-model="power.value">
        </div>
        <div v-if="!year.hidden" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': year.disabled }">
          <label>{{ year.title }}</label>
          <input type="number" v-model="year.value">
        </div>
        <div v-if="!ownershipDate.hidden" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': ownershipDate.disabled }">
          <label>{{ ownershipDate.title }}</label>
          <date-picker :formatter="momentFormat" v-model="ownershipDateModel"></date-picker>
        </div>
        <div v-if="!vehicleCost.hidden" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': vehicleCost.disabled }">
          <label>{{ vehicleCost.title }}</label>
          <input type="text" v-model="modelVehicleCost" onkeyup="this.value = this.value.replace(/[^\d\s.,]/g,'');">
        </div>
        <div v-if="!period.hidden" class="property-valuation__select lk__field"
          :class="{ 'property-valuation__select--disabled': period.disabled }">
          <label>{{ period.title }}</label>
          <input type="text" v-model="periodModel" onkeyup="this.value = this.value.replace(/[^\d\s.,]/g,'');"/>
          <!-- <date-picker :formatter="momentFormat" range v-model="period.value"></date-picker> -->
        </div>
        <div v-if="result.isLoading" class="messages apollo property-valuation__select lk__field">
          <div class="messages__status">Загрузка</div>
        </div>
        <div class="btn lk__field" @click="calculate">Расчитать</div>
        <!-- <template v-if="result.graph.length">
          <div
            class="property-valuation__select lk__field"
            v-for="(graph, index) in result.graph"
            :key="index"
          >
            <label>Сумма страховой премии {{ index + 1 }} год</label>
            <input type="text" disabled :value="graph" />
          </div>
        </template> -->
        <div v-if="result.premiumSumm" class="property-valuation__select lk__field">
          <label>Сумма страховой премии</label>
          <input type="text" disabled :value="result.premiumSumm.toLocaleString() + ' ₽'" />
        </div>
        <div v-if="errors && errors.length" class="messages apollo property-valuation__select lk__field">
          <label>Ошибки:</label>
          <div class="messages__status error" v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </div>
        <div v-if="result.comments && result.comments.length" class="messages apollo property-valuation__select lk__field">
          <label>Комментарии:</label>
          <div class="messages__status" v-for="(comment, index) in result.comments" :key="index">{{ comment }}</div>
        </div>
        <div v-if="result.message" class="messages apollo property-valuation__select lk__field">
          <label>Сообщение:</label>
          <div class="messages__status" >{{ result.message }}</div>
        </div>
      </div>
      <div class="lk__back">
        <AutoIcon />
      </div>
    </div>
  </div>
</template>

<script>
import SelectInput from '@/components/ui/GraphInputs/SelectInput.vue'
import CompanyInput from '@/components/ui/GraphInputs/PartnerInput'

import AutoIcon from '@/components/svg/Auto.vue'

import moment from 'moment'
// import { extendMoment } from 'moment-range'
import axios from 'axios'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'

// const _moment = extendMoment(moment)
// const Today = _moment()
// const Tomorrow = _moment().add(1, 'days')
// const Week = _moment().endOf('week')

const URL = `${process.env.VUE_APP_HTTP}/api/alfastrah`

export default {
  name: 'Insurance',
  components: {
    SelectComponent: SelectInput,
    CompanyInput,
    DatePicker,
    AutoIcon
  },
  data () {
    return {
      errors: [],
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('DD.MM.YYYY') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'DD.MM.YYYY').toDate() : null
        }
      },
      company: {
        id: null,
        title: 'Компания',
        options: [],
        fullname: null,
        name: null,
        inn: null,
        kpp: null,
        value: null,
        hidden: false,
        disabled: false
      },
      mark: {
        title: 'Марка',
        options: [],
        value: null,
        hidden: false,
        disabled: false
      },
      model: {
        title: 'Модель',
        options: [],
        value: null,
        hidden: false,
        disabled: true
      },
      newTs: {
        title: 'Новое ТС',
        options: [
          {
            label: 'Да',
            value: 'true'
          },
          {
            label: 'Нет',
            value: 'false'
          }
        ],
        value: {
          label: 'Нет',
          value: 'false'
        },
        hidden: true,
        disabled: true
      },
      power: {
        title: 'Мощность (лс)',
        value: 180,
        hidden: true,
        disabled: true
      },
      year: {
        title: 'Год выпуска',
        value: '2000',
        hidden: true,
        disabled: true
      },
      ownershipDate: {
        title: 'Дата передачи',
        value: moment().add(1, 'days').toDate(),
        hidden: true,
        disabled: true
      },
      vehicleCost: {
        title: 'Стоимость',
        value: 8000000,
        hidden: true,
        disabled: true
      },
      period: {
        title: 'Период (месяцев)',
        value: [
          moment().toDate(),
          moment().add(6, 'months').toDate()
        ],
        mounth: 12,
        hidden: false,
        disabled: true
      },
      result: {
        premiumSumm: 0,
        graph: [],
        comments: [],
        message: null,
        isLoading: false
      }
    }
  },
  computed: {
    ownershipDateModel: {
      get () {
        return this.ownershipDate.value
      },
      set (value) {
        this.ownershipDate.value = value
        this.period.value[0] = value
      }
    },
    periodModel: {
      get () {
        return this.period.mounth
      },
      set (value) {
        const newValue = value.toString()
        this.period.mounth = +(newValue.replace(/[^\d.,]/ig, ''))
        if (isNaN(this.period.mounth) || typeof this.period.mounth !== 'number') this.period.mounth = 0

        this.period.value[0] = this.ownershipDate.value
        this.period.value[1] = moment().add(this.period.mounth, 'months').toDate()
      }
    },
    modelVehicleCost: {
      get () {
        return parseFloat(this.vehicleCost.value).toLocaleString('ru-RU')
      },
      set (value) {
        const newValue = value.toString().replace(',', '.')
        this.vehicleCost.value = +(newValue.replace(/[^\d.,]/ig, ''))
        if (isNaN(this.vehicleCost.value) || typeof this.vehicleCost.value !== 'number') this.vehicleCost.value = 0
      }
    }
  },
  created () {
    this.getMarks()
  },
  methods: {
    preventE (event) {
      event.preventDefault()
    },
    periodInput (e) {
      const value = e.data
      if (value === 'e') {
        return
      }

      if (/^(0|[1-9]\d*)$/.test(value)) {
        this.period.mounth = value
        this.period[0] = this.ownershipDate.value
        this.period[1] = moment().add(value, 'months').toDate()
      }
    },
    // Разделение числа на массив чисел кратному знаменателю
    splitIntoMultiplesOfDenominator (n, denominator) {
      if (n <= 0) {
        throw new Error('Input must be a positive integer.')
      }

      const result = []
      let remainder = n

      while (remainder >= denominator) {
        result.push(denominator)
        remainder -= denominator
      }

      if (remainder > 0) {
        result.push(remainder)
      }

      return result
    },
    createGraphPeriodList (beginDate, mounthPeriodList) {
      const result = []
      let date = beginDate
      for (const mounthPeriod of mounthPeriodList) {
        const endDate = moment(date).add(mounthPeriod, 'months').toDate()
        result.push({
          beginDate: date,
          endDate: endDate
        })
        date = endDate
      }
      return result
    },
    async graphCalculate () {
      const mounthPeriodList = this.splitIntoMultiplesOfDenominator(this.period.mounth, 12)
      const graphPeriodList = this.createGraphPeriodList(new Date(), mounthPeriodList)

      this.result.premiumSumm = 0
      this.result.comments = []
      this.result.message = null
      this.result.graph = []

      let index = 0

      for (const graphPeriod of graphPeriodList) {
        this.result.isLoading = true
        index++
        this.errors = []

        const url = `${URL}/calculate`

        await axios.post(url, {
          organization: {
            fullName: this.company.fullname,
            shortName: this.company.name,
            inn: this.company.inn
          },
          insuranceTerm: {
            beginDate: graphPeriod.beginDate,
            endDate: graphPeriod.endDate
          },
          vehicle: {
            brand: this.mark.value,
            model: this.model.value,
            newTS: this.newTs.value.value,
            power: this.power.value,
            year: this.year.value,
            ownershipDate: index === 0 ? this.ownershipDate.value : graphPeriod.beginDate,
            vehicleCost: this.vehicleCost.value
          }
        })
          .then(res => {
            this.result.graph.push(res.data.calcResult.totalPremium)
            return res
          })
          .catch(err => {
            if (err.response) {
              this.errors = [...this.errors, err.response.data.message]
              this.result.graph.push(err.response.data.message)
            }
            return err
          })
          .finally(() => {
            this.result.isLoading = false
          })
      }
    },
    async calculate () {
      this.result = {
        premiumSumm: 0,
        comments: [],
        message: null,
        isLoading: true
      }
      this.errors = []

      const url = `${URL}/calculate`

      await axios.post(url, {
        organization: {
          fullName: this.company.fullname,
          shortName: this.company.name,
          inn: this.company.inn
        },
        insuranceTerm: {
          beginDate: this.period.value[0],
          endDate: this.period.value[1]
        },
        vehicle: {
          brand: this.mark.value,
          model: this.model.value,
          newTS: this.newTs.value.value,
          power: this.power.value,
          year: this.year.value,
          ownershipDate: this.ownershipDate.value,
          vehicleCost: this.vehicleCost.value
        }
      })
        .then(res => {
          this.result = {
            premiumSumm: res.data.total.insuranceBonus,
            comments: res.data.calcResult.comments,
            message: res.data.calcResult.message
          }
          return res
        })
        .catch(err => {
          if (err.response) {
            this.errors = [...this.errors, err.response.data.message]
          }
          return err
        })
        .finally(() => {
          this.result.isLoading = false
        })
    },
    async getMarks () {
      const url = `${URL}/marks`

      const response = await axios.get(url)
        .catch(err => {
          if (err.response) {
            this.errors = [...this.errors, err.response.data.message]
          }
        })

      if (!response) return

      const data = response.data
        .map(elem => ({
          label: elem.mark,
          value: elem.mark_id
        }))

      this.mark.options = this.getUniq(data, 'value')

      return response.data
    },
    async getModels (markId) {
      const url = `${URL}/marks/${markId}/models`

      const response = await axios.get(url)
        .catch(err => {
          if (err.response) {
            this.errors = [...this.errors, ...err.response.data.message]
          }
        })

      if (!response) return

      const data = response.data
        .map(elem => ({
          label: elem.model,
          value: elem.model_id
        }))

      this.model.options = this.getUniq(data, 'value')

      return response.data
    },
    onMarkChange (e) {
      const selectedElem = e ? e.value : null
      this.mark.value = selectedElem

      this.getModels(selectedElem)
      this.model.disabled = !selectedElem
      this.model.hidden = !selectedElem
    },
    onModelChange (e) {
      const selectedElem = e ? e.value : null
      this.model.value = selectedElem

      this.period.disabled = false
      this.period.hidden = false

      this.newTs.disabled = false
      this.newTs.hidden = false

      this.power.disabled = false
      this.power.hidden = false

      this.year.disabled = false
      this.year.hidden = false

      this.ownershipDate.disabled = false
      this.ownershipDate.hidden = false

      this.vehicleCost.disabled = false
      this.vehicleCost.hidden = false
    },
    onNewTsChange (e) {
      this.newTs.value = e
      this.period.disabled = false
    },
    onCompanyChange (item) {
      this.company.name = item.name
      this.company.fullname = item.fullname
      this.company.inn = item.inn
      this.company.kpp = item.kpp
    },
    getUniq (array, key) {
      return array.reduce((o, i) => {
        if (!o.find(v => v[key] === i[key])) {
          o.push(i)
        }
        return o
      }, [])
    }
  }
}
</script>

<style lang="stylus" scoped>
.property-valuation
  &__line
    border-bottom 1px solid $darkgray
    height 1px
    width 100%

  &__select
    margin-bottom 10px !important

    &--disabled
      pointer-events none
      opacity 0.3
</style>
