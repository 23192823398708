<template>
  <div class="graph-search-input">
    <input type="text" v-model="currentText" :placeholder="placeholder" @input="onChangeInput">
    <template v-if="showLoading">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" xml:space="preserve">
        <path fill="#ff8100" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
          <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"/>
        </path>
      </svg>
    </template>
    <div class="graph-search-input__select lsg-popover" v-if="currentText && searchItems.length && isVisibleSearch">
      <div
          v-for="(item, index) in searchItems"
          :key="index"
          class="graph-search-input__select-block"
          @click="chooseItem(item)"
      >
        {{ item.name }}
        <br v-if="columnName !== 'name'">
        <template v-if="columnName !== 'name'">{{ item[columnName] }}</template>
      </div>
    </div>
  </div>
</template>

<script>
import SEARCH_PARTNERS from '@/graphql/queries/SearchPartners.gql'

export default {
  name: 'CompanyInput',
  props: ['filterName', 'columnName', 'placeholder', 'currentValue'],
  data: () => {
    return {
      timeout: null,
      showLoading: false,
      currentText: '',
      searchItems: [],
      isVisibleSearch: false,
      isChangedInParent: false,
      filter: {}
    }
  },
  watch: {
    searchItems (value) {
      if (this.isChangedInParent) {
        this.isVisibleSearch = false
        this.isChangedInParent = false

        return
      }
      if (value.length) {
        this.isVisibleSearch = true
      }
    },
    currentValue (value) {
      this.isChangedInParent = true
      this.currentText = this.currentValue
    },
    currentText (value) {
      if (value !== '' && value !== this.currentValue) {
        clearTimeout(this.timeout)
        this.showLoading = true
        this.timeout = setTimeout(() => {
          this.search()
        }, 500)
      }
    }
  },
  methods: {
    search () {
      this.$apollo.mutate({
        mutation: SEARCH_PARTNERS,
        variables: {
          page: 0,
          perPage: 10,
          sortField: this.columnName,
          sortOrder: 'asc',
          filter: this.getFilter()
        },
        update: (store, { data }) => {
          this.showLoading = false
          this.searchItems = data.allPartners || []
        }
      })
    },
    chooseItem (item) {
      this.isVisibleSearch = false
      this.$emit('change', item)
    },
    getFilter () {
      let currentFilter = {}

      if (this.currentText) {
        currentFilter[this.filterName] = this.currentText
      }

      return currentFilter
    },
    updateGraphSearchInputSelect () {
      if (event.target.closest('.lsg-popover')) return

      this.isVisibleSearch = false
    },
    onChangeInput () {
      if (this.isChangedInParent) return
      this.$emit('changeInput', { columnName: this.columnName, value: this.currentText })
    }
  },

  mounted () {
    document.addEventListener('click', this.updateGraphSearchInputSelect)
  }
}
</script>
